import {StatusBar} from 'expo-status-bar';
import * as ScreenOrientation from 'expo-screen-orientation'
import {
    Alert, Text, View, FlatList, ActivityIndicator, Image, Platform, TouchableOpacity, ScrollView, SafeAreaView
} from 'react-native';
import {FontAwesome} from '@expo/vector-icons';

import * as React from 'react';
import Constants from "expo-constants";
import {AdminLogout} from "../../App";
import * as Linking from "expo-linking";
import {UploadDoc} from "../../scripts/admin/doc";
import {Fetch, SpecificFetch} from "../../scripts/common/fetch";
import Avviso from "./component/avviso";

const larg = '3%'
const larg2 = '6%'
const larg3 = '9%'
const larg4 = '12%'

const isWeb = Platform.OS === 'web'

export default function AdminHome({navigation}) {
    //ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.LANDSCAPE_RIGHT);

    const [refresh, setRefresh] = React.useState(false)

    const [soci, setSoci] = React.useState([]);
    const [corsi, setCorsi] = React.useState([]);
    const [count, setCount] = React.useState(null);
    const [space, setSpace] = React.useState(null);

    React.useEffect(() => {
        const bootstrapAsync = async () => {
            const [soci, corsi, count, space] = await Promise.all([
                //Fetch('elenco', 'SELECT * FROM socio'), //soci.php
                //Fetch('elenco', 'SELECT cfSocio, nome FROM sociocorso join corso on idCorso=id'), //sociCorsi.php
                //Fetch('obj', 'SELECT COUNT(*) as count FROM socio') //count.count
                SpecificFetch('soci.php'),
                SpecificFetch('sociCorsi.php'),
                SpecificFetch('administration/count.php'),
                SpecificFetch('space.php')
            ])
            setSoci(soci)
            setCorsi(corsi)
            setCount(count)
            setSpace(Math.round((100 - space) * 100) / 100)
        }
        bootstrapAsync()
        setRefresh(false)
    }, [refresh]);

    async function DeleteSocio(email) {
        SpecificFetch('administration/deleteAccount.php', {email: email})
            .then(response => Avviso(response))
            .finally(() => setRefresh(true));
    }

    async function AttivaSocio(email, nome) {
        SpecificFetch('administration/attivaAccount.php', {email: email, nome: nome})
            .then(response => Avviso(response))
            .finally(() => setRefresh(true));
    }

    /*async function Email(email, nome, text) {
        const dominio = await AsyncStorage.getItem('dominio')
        fetch(dominio + 'administration/email.php', {
            method: 'POST',
            body: JSON.stringify({
                email: email, nome: nome, text: text
            })
        }).then((response) => response.json())
            .then((responseJson) => {
                !isWeb ? Alert.alert(responseJson) : alert(responseJson)
            }).catch((error) => {
            console.error(error);
        }).finally(() => setRefresh(true));
    }*/

    async function DisattivaSocio(email) {
        SpecificFetch('administration/disattivaAccount.php', {email: email})
            .then(response => Avviso(response))
            .finally(() => setRefresh(true));
    }

    function Title(props) {
        return (<View style={{
            alignItems: 'center',
            justifyContent: 'center',
            width: props.stile,
            borderLeftWidth: 1,
            borderTopWidth: 1,
            minWidth: props.minwidth ? props.minwidth : 100,
            maxWidth: props.maxwidth ? props.maxwidth : 200,
            borderRightWidth: props.border,
            borderBottomWidth: 1
        }}>
            <Text style={{
                fontSize: 11
            }}>{props.nome}
            </Text>
        </View>)
    }

    /*function Lista(props) {
        return (<View style={{
            //height: 120
        }}>
            <FlatList data={props.corsi}
                      keyExtractor={(item, index) => index.toString()}
                      renderItem={({item}) => (<Text style={{
                          fontSize: 11, textAlign: 'center'
                      }}>{item.nome}
                      </Text>)}/>
        </View>)
    }*/

    function Lista(props) {
        let vettor = [];
        corsi.forEach(item => {
            if (item.cfSocio === props.cf) {
                const obj = {
                    nome: item.nome
                }
                vettor.push(obj)
            }

        })
        return (
            <View style={{
                //height: 120
            }}>
                <FlatList data={vettor}
                          keyExtractor={(item, index) => index.toString()}
                          renderItem={({item}) => (<Text style={{
                              fontSize: 11, textAlign: 'center'
                          }}>{item.nome}
                          </Text>)}/>
            </View>
        )
    }

    function Cella(props) {
        return (<View style={{
            alignItems: 'center',
            justifyContent: 'center',
            width: !isWeb ? larg : larg2,
            minWidth: 100,
            borderLeftWidth: 1,
            borderBottomWidth: 1
        }}>
            <Text style={{
                fontSize: 11
            }}>{props.content}
            </Text>
        </View>)
    }

    return (<View style={{
        flex: 1, backgroundColor: 'white', paddingTop: Constants.statusBarHeight,
    }}>

        <View style={{
            height: '10%', margin: 10, alignItems: 'center'
        }}>
            <Image source={require('../../assets/logo.jpg')}
                   style={{
                       resizeMode: 'contain', aspectRatio: 4, width: '100%',//isWeb ? 2502 / 100 * 20 : 2502 / 100 * 10,
                       height: '100%',//isWeb ? 1023 / 100 * 20 : 1023 / 100 * 10,
                   }}/>

        </View>

        <View style={{
            flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around'
        }}>
            <View>
                <Text style={{
                    fontSize: 16,
                }}>SOCI REGISTRATI: {count}</Text>
            </View>
            <View>
                <AdminLogout/>
            </View>
        </View>
        <View style={{
            alignItems: 'center'
        }}>
            <Text style={{
                fontSize: 16,
            }}>SPAZIO DISPONIBILE: {space}/100 GB</Text>
        </View>
        <View style={{
            flex: 1,
            //alignItems: 'center'
        }}>
            <ScrollView horizontal
                //showsHorizontalScrollIndicator={false}
                        style={{}}>
                <View style={{
                    flex: 1,
                    margin: 10,
                    //alignItems: 'center'
                }}>
                    {/* navbar */}
                    {/*<Navbar navigation={navigation} adminHome={true}/>*/}
                    {/* /navbar */}

                    {/* navbar2 */}
                    {/*<View style={{
                            alignItems: 'flex-end',
                            marginRight: '10%',
                            marginBottom: 10,
                        }}>

                            <View>
                                <Text>SOCI REGISTRATI: {count}</Text>
                            </View>

                            <View style={{
                                flexDirection: 'row',
                                alignItems: 'center',
                                marginBottom: 5,
                            }}>
                                <FontAwesome name="envelope" size={30} color="black"/>
                                <Text style={{
                                    marginLeft: 15,
                                }}>CREA NOTIFICA</Text>
                            </View>
                            <View style={{
                                flexDirection: 'row',
                                alignItems: 'center',
                                marginBottom: 5
                            }}>
                                <FontAwesome name="plus" size={30} color="green"/>
                                <Text style={{
                                    marginLeft: 5,
                                }}>AGGIUNGI SOCIO</Text>
                            </View>
                        </View>*/}
                    {/* /navbar2 */}

                    {/* body */}
                    <View style={{
                        flexDirection: 'row', //justifyContent: 'center',
                    }}>
                        <Title nome='NOME' stile={!isWeb ? larg : larg2}/>
                        <Title nome='COGNOME' stile={!isWeb ? larg : larg2}/>
                        <Title nome='SESSO' stile={!isWeb ? larg : larg2} minwidth={50} maxwidth={50}/>
                        <Title nome='CORSO' stile={!isWeb ? larg : larg2}/>
                        <Title nome='CELLULARE' stile={!isWeb ? larg : larg2}/>
                        <Title nome='EMAIL' stile={!isWeb ? larg2 : larg4} minwidth={200}/>
                        <Title nome='CF' stile={!isWeb ? larg2 : larg3} minwidth={150}/>
                        <Title nome='NOTIFICA' stile={!isWeb ? larg : larg2}/>
                        <Title nome='DOC RAPIDO' stile={!isWeb ? larg : larg2}/>
                        <Title nome='ATTIVO' stile={!isWeb ? larg : larg2}/>
                        <Title nome='ELIMINA' stile={!isWeb ? larg : larg2} border={1}/>
                    </View>
                    <FlatList data={soci.sort((a, b) => a.nome.localeCompare(b.nome))}
                        //ItemSeparatorComponent = {{ height: 0.5, width: "100%", backgroundColor: "#000" }}
                              showsVerticalScrollIndicator={false}
                              showsHorizontalScrollIndicator={false}
                              keyExtractor={(item, index) => index.toString()}
                              renderItem={({item, index}) =>

                                  <View style={{
                                      flexDirection: 'row', //justifyContent: 'center',
                                      //alignItems: 'center'
                                  }}>
                                      <View style={{
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                          width: !isWeb ? larg : larg2,
                                          minWidth: 100,
                                          borderLeftWidth: 1,
                                          borderBottomWidth: 1
                                      }}>
                                          <Text style={{
                                              fontSize: 11
                                          }}>{item.nome}
                                          </Text>
                                      </View>
                                      <View style={{
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                          width: !isWeb ? larg : larg2,
                                          minWidth: 100,
                                          borderLeftWidth: 1,
                                          borderBottomWidth: 1
                                      }}>
                                          <Text style={{
                                              fontSize: 11
                                          }}>{item.cognome}
                                          </Text>
                                      </View>

                                      <View style={{
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                          width: !isWeb ? larg : larg2,
                                          minWidth: 50,
                                          maxWidth: 50,
                                          borderLeftWidth: 1,
                                          borderBottomWidth: 1
                                      }}>
                                          <Text style={{
                                              fontSize: 11
                                          }}>{item.sesso}
                                          </Text>
                                      </View>

                                      <View style={{
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                          width: !isWeb ? larg : larg2,
                                          minWidth: 100,
                                          borderLeftWidth: 1,
                                          borderBottomWidth: 1
                                      }}>
                                          <Lista //corsi={item.corsi}
                                              cf={item.cf}
                                          />
                                      </View>
                                      <View style={{
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                          width: !isWeb ? larg : larg2,
                                          minWidth: 100,
                                          borderLeftWidth: 1,
                                          borderBottomWidth: 1
                                      }}>
                                          <Text
                                              onPress={() => Linking.openURL('tel: +39' + item.cellulare)}
                                              style={{
                                                  fontSize: 11
                                              }}>{item.cellulare}
                                          </Text>
                                      </View>
                                      <View style={{
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                          width: !isWeb ? larg2 : larg4,
                                          minWidth: 200,
                                          borderLeftWidth: 1,
                                          borderBottomWidth: 1
                                      }}>
                                          <Text style={{
                                              fontSize: 11
                                          }}>{item.email}
                                          </Text>
                                      </View>
                                      <View style={{
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                          width: !isWeb ? larg2 : larg3,
                                          minWidth: 150,
                                          borderLeftWidth: 1,
                                          borderBottomWidth: 1
                                      }}>
                                          <Text style={{
                                              fontSize: 11
                                          }}>{item.cf}
                                          </Text>
                                      </View>
                                      <View style={{
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                          width: !isWeb ? larg : larg2,
                                          minWidth: 100,
                                          borderLeftWidth: 1,
                                          borderBottomWidth: 1
                                      }}>
                                          <FontAwesome name="envelope" size={24} color="black"
                                              //onPress={()=> Email(item.email, item.nome)}
                                          />
                                      </View>

                                      <View style={{
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                          width: !isWeb ? larg : larg2,
                                          minWidth: 100,
                                          borderLeftWidth: 1,
                                          borderBottomWidth: 1
                                      }}>
                                          <FontAwesome name="send" size={24} color="black"
                                                       onPress={() => UploadDoc('Personali', null, item.cf)}
                                          />
                                      </View>

                                      <View style={{
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                          width: !isWeb ? larg : larg2,
                                          minWidth: 100,
                                          borderLeftWidth: 1,
                                          borderBottomWidth: 1
                                      }}>
                                          {item.attivo === '1' ? <FontAwesome name="check" size={24} color="green"
                                                                              onPress={() => DisattivaSocio(item.email)}/> :
                                              <FontAwesome name="remove" size={24} color="red"
                                                           onPress={() => AttivaSocio(item.email, item.nome)}/>}
                                      </View>

                                      <View style={{
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                          width: !isWeb ? larg : larg2,
                                          minWidth: 100,
                                          borderLeftWidth: 1,
                                          borderRightWidth: 1,
                                          borderBottomWidth: 1
                                      }}>
                                          <FontAwesome name="trash-o" size={24} color="black"
                                                       onPress={() => DeleteSocio(item.email)
                                                           /*!isWeb ?
                                                           Alert.alert('Vuoi davvero eliminare l\'account di questo socio?', [{
                                                               text: "ANNULLA", style: "cancel"
                                                           }, {
                                                               text: "ELIMINA",
                                                               onPress: () => DeleteSocio(item.email)
                                                           }])
                                          : DeleteSocio(item.email)*/

                                                       }/>
                                      </View>
                                  </View>}
                    />
                </View>
                {/* body */}

                {/*<StatusBar style="auto"/>*/}
            </ScrollView>
        </View>
    </View>)
}