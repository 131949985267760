import {
    Alert,
    FlatList,
    Image,
    Modal,
    RefreshControl,
    ScrollView,
    Text, TextInput,
    TouchableOpacity,
    View,
    Animated, Dimensions
} from "react-native";
import * as React from "react";
import Constants from 'expo-constants';
import AsyncStorage from "@react-native-async-storage/async-storage";
import {PanGestureHandler, PinchGestureHandler, State} from 'react-native-gesture-handler';
import {isWeb} from "../../global";
import {Ionicons, MaterialIcons} from "@expo/vector-icons";
import {GoBack} from "./goBack";
import * as DocumentPicker from "expo-document-picker";
import Modifica from "../admin/component/modifica";
import Evento from "../admin/component/evento";
import Salva from "../admin/component/salva";
import Elimina from "../admin/component/elimina";
import Update from "../admin/component/update";


export default function Eventi({navigation}) {
    const [loading, setLoading] = React.useState(false)
    const [dominio, setDominio] = React.useState(null);
    const [eventi, setEventi] = React.useState([]);
    const [refresh, setRefresh] = React.useState(false)
    const [user, setUser] = React.useState(null);
    const [show, setShow] = React.useState(false);
    const [selected, setSelected] = React.useState(null);
    const [modifica, setModifica] = React.useState(null);
    const [nome, setNome] = React.useState('')

    React.useEffect(() => {
        // Fetch the token from storage then navigate to our appropriate place
        const bootstrapAsync = async () => {
            //await ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.LANDSCAPE)

            const user = await AsyncStorage.getItem('login')
            setUser(user)

            const dominio = await AsyncStorage.getItem('dominio')
            setDominio(dominio)
            fetch(dominio + 'eventi/eventi.php', {
                method: 'POST',
                body: JSON.stringify({
                    scelta: 'read'
                })
            }).then((response) => response.json())
                .then((responseJson) =>
                    setEventi(responseJson)
                ).catch((error) => {
                console.error(error);
            })
        }
        bootstrapAsync()
        setRefresh(false)
    }, [refresh]);

    function Delete(nome) {
        fetch(dominio + 'eventi/eventi.php', {
            method: 'POST',
            body: JSON.stringify({
                scelta: 'delete',
                nome: nome
            })
        }).then((response) => response.json())
            .then((responseJson) => {
                    isWeb ? alert(responseJson) :
                        Alert.alert(responseJson)
                    setRefresh(!refresh)
                }
            ).catch((error) => {
            console.error(error);
        })//.finally()
    }

    function Upload(name, type, uri) {
        //console.log((uri.split(':')) )
        let scelta;
        if (uri.split(':')[0] === 'data') {
            uri = uri.split(',')[1]
            //console.log(uri)
            scelta = 'web'

            fetch(dominio + 'eventi/upload.php', {
                method: 'POST',
                /*header: {
                    'content-type': 'multipart/form-data',
                },*/
                body: JSON.stringify({
                    scelta: scelta,
                    name: nome + '.' + name.split('.')[1],
                    type: type,
                    uri: uri
                })
            }).then((response) => response.json())
                .then((responseJson) => {
                    setLoading(false)
                    isWeb ? alert(responseJson) :
                        Alert.alert(responseJson)
                    setRefresh(true)
                }).catch((error) => {
                console.error(error);
            })

        } else {
            let formData = new FormData();
            formData.append('image', {
                uri: uri,
                name: nome + '.' + name.split('.')[1],
                type: type
            });

            fetch(dominio + 'eventi/upload.php', {
                method: 'POST',
                header: {
                    'content-type': 'multipart/form-data',
                },
                body: formData
            }).then((response) => response.json())
                .then((responseJson) => {
                    setLoading(false)
                    isWeb ? alert(responseJson) :
                        Alert.alert(responseJson)
                    setRefresh(true)
                }).catch((error) => {
                console.error(error);
            })

        }
    }

    const pickImage = async () => {
        if (!nome || nome === '' || nome.lenght < 8) {
            return (
                isWeb ? alert('Inserisci una data corretta') :
                    Alert.alert('Inserisci una data corretta')
            )
        } else {

            // No permissions request is necessary for launching the image library
            let result = await DocumentPicker.getDocumentAsync({
                //let result = await ImagePicker.launchImageLibraryAsync({
                //mediaTypes: ImagePicker.MediaTypeOptions.All,
                //allowsEditing: true,
                //aspect: [4, 3],
                //quality: 1,
                //allowsMultipleSelection: true
                type: 'image/*'
            });

            //console.log(result)

            if (result.type === 'success') {
                setLoading(true)

                Upload(result.name, result.mimeType, result.uri)
            }
        }
    };

    return (
        /* {<View style={{flex: 1, alignItems: 'center', justifyContent: 'center'}}>
             <Button title="Pick an image from camera roll" onPress={pickImage}/>
             {images && <Image source={{uri: images}} style={{width: 200, height: 200}}/>}
             <Button title='CLICK' onPress={() => Upload()}/>
         </View>}*/

        <View style={{
            flex: 1,
            backgroundColor: 'white',
            paddingTop: Constants.statusBarHeight,
        }}>

            <GoBack function={() => {
                navigation.goBack()
            }} color='black'/>

            <Modal
                animationType="fade"
                visible={show}
                onRequestClose={() => {
                    setShow(!show);
                }}>

                <GoBack color='black'
                        function={() => setShow(!show)}
                        function2={() => {
                            navigation.navigate('Home')
                            setShow(!show)
                        }}/>


                <View style={{
                    flex: 1,
                    margin: 10
                }}>

                    <Image
                        style={{
                            resizeMode: 'contain',
                            width: '100%',
                            height: '100%',
                        }}
                        source={{
                            uri: dominio + 'eventi/' + selected
                        }}/>

                </View>
            </Modal>

            <Update loading={loading}/>

            {!eventi ?

                <ScrollView
                    refreshControl={
                        <RefreshControl
                            refreshing={refresh}
                            onRefresh={() => setRefresh(true)}
                        />
                    }
                >
                    <View style={{
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        {user === 'admin' ?
                            <View>
                                <TextInput
                                    placeholder="INSERISCI DATA EVENTO es: AAAAMMDD"
                                    onChangeText={data => setNome(data)}
                                    maxLength={8}
                                    keyboardType='numeric'
                                    style={{
                                        borderWidth: 1,
                                        marginTop: 10,
                                        padding: 3,
                                        width: 265
                                    }}
                                />
                                <Text style={{
                                    color: 'red',
                                    marginBottom: 5
                                }}>{nome === '' ? 'campo obbligatorio' : '' ||
                                nome ? nome.length !== 8 ? 'Formato errato' : '' : ''}</Text>
                                <Evento function={() => pickImage()}/>
                            </View>
                            : null}

                        <View style={{
                            //flex: 1,
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>

                            {/*isWeb ?

                                <TouchableOpacity
                                    style={{
                                        margin: 20
                                    }}
                                    onPress={() => setRefresh(true)}
                                >
                                    <Ionicons name="refresh" size={26} color='black'/>
                                </TouchableOpacity>

                                : null*/}

                            <Image source={require('../../assets/iconPhotoX.png')} style={{
                                //resizeMode: 'contain',
                                width: 100,
                                height: 100
                            }}/>
                            <Text>NESSUNA EVENTO PRESENTE</Text>
                        </View>
                    </View>
                </ScrollView>

                :

                <View style={{flex: 1}}>

                    {user === 'admin' ?
                        modifica ?

                            <View>
                                <View style={{alignItems: 'center'}}>
                                    <TextInput
                                        placeholder="INSERISCI DATA EVENTO es: AAAAMMDD"
                                        onChangeText={data => setNome(data)}
                                        maxLength={8}
                                        keyboardType='numeric'
                                        style={{
                                            borderWidth: 1,
                                            marginTop: 10,
                                            padding: 3,
                                            width: 265
                                        }}
                                    />
                                    <Text style={{
                                        color: 'red',
                                        marginBottom: 5
                                    }}>{nome === '' ? 'campo obbligatorio' : '' ||
                                    nome ? nome.length !== 8 ? 'Formato errato' : '' : ''}</Text>
                                    <Evento function={() => pickImage()}/>
                                </View>
                                <View style={{marginRight: '10%', alignItems: 'flex-end'}}>
                                    <Salva function={() => setModifica(!modifica)}/>
                                </View>
                            </View>
                            :
                            <View style={{marginLeft: '10%'}}>

                                <Modifica function={() => setModifica(!modifica)}/>
                            </View>
                        : null}
                    <View style={{flex: 1, alignItems: 'center'}}>

                        {/*isWeb ?

                            <TouchableOpacity
                                style={{
                                    margin: 20
                                }}
                                onPress={() => setRefresh(true)}
                            >
                                <Ionicons name="refresh" size={26} color='black'/>
                            </TouchableOpacity>

                            : null*/}

                        <FlatList data={eventi.sort((a, b) => a.localeCompare(b))}
                                  onRefresh={() => setRefresh(true)}
                                  refreshing={refresh}
                                  showsVerticalScrollIndicator={false}
                                  keyExtractor={(item, index) => index.toString()}
                                  renderItem={({item}) =>
                                      <View style={{
                                          flexDirection: 'row',
                                          alignItems: 'center',
                                          margin: 15
                                      }}>
                                          <TouchableOpacity
                                              onPress={() => {
                                                  setShow(!show)
                                                  setSelected(item)
                                              }}
                                          >
                                              <Image
                                                  style={{
                                                      resizeMode: 'contain',
                                                      width: 250,
                                                      height: 250
                                                  }}
                                                  source={{
                                                      uri: dominio + 'eventi/' + item,
                                                  }}/>
                                          </TouchableOpacity>

                                          {user === 'admin' ?

                                              modifica ?

                                                  <Elimina function={() => Delete(item)}/>

                                                  : null : null}

                                      </View>
                                  }/>
                    </View>
                </View>
            }
        </View>

    )
}